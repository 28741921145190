import React from 'react';
import style from './blurbs.module.scss';

export const Blurbs = () => (
    <div className="container">
        <div className="row">
            <Blurb>
                Is your friendship garden looking more like a cactus? Use us to find new people you
                like.
            </Blurb>
            <Blurb>
                Worried about your treasury? Sign up will always be free. We’ll offer some super cool
                upgrades if you’re interested, but you will never have to pay to find that perfect
                guild for you.
            </Blurb>
            <Blurb>
                Interested in running your own group? Guild Me has both individual profiles and group
                profiles. Build your own guild and invite players to join.
            </Blurb>
        </div>
    </div>
);

const Blurb: React.FC = ({children}) => (
    <div className="col-xs-12 col-lg-4 px-2 px-lg-4 pb-2 pb-sm-4 pb-lg-1">
        <div className={`card text-light shadow h-100 ${style.extensions}`}>
            <div className="card-body">
                <p className="lead">
                    { children }
                </p>
            </div>
        </div>
    </div>
);

export default Blurbs;
