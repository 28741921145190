import React from 'react';
import Blurbs from './components/Blurbs/Blurbs';
import Hero from './components/Hero/Hero';

export const App = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <Hero />
      <Blurbs />
      <div className="container py-2">
        <div className="h2 pt-4 text-accent-2 text-center">
          Check back soon! We're working hard to unlock more content.
        </div>
      </div>
    </div>
  );
}

export default App;
