import React from 'react';
import logo from '../../assets/images/logos/lighttext@4x.png';
import styles from './hero.module.scss';

const Hero = () => (
    <header className={`${styles.hero} jumbotron jumbotron-fluid darker shadow`} >
        <div className="text-center container d-flex flex-column">
            <div className="d-flex flex-column flex-md-row">
                <div className="w-50 p-2 m-auto">
                    <img src={logo} className={`${styles.logo}`} alt="logo"/>
                </div>
                <div className="flex-shrink-1 text-center text-md-left p-2 p-md-4">
                    <p className="display-4">
                        Find other <span className="text-accent-1">gamers</span> you will actually <span className="text-accent-2">enjoy</span> playing with.
                    </p>
                    <hr className="bg-primary"/>
                    <p className="lead">
                        Ditch the random matchmaking and control your own destiny!
                    </p>
                </div>
            </div>
        </div>
    </header>
);

export default Hero
